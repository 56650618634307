import React from 'react';
import { Button, Dropdown, Nav, NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  applyMask,
  isPermittedNetwork,
  skynityConfig,
  switchToChain,
} from '../../helpers';
import {
  selectConnectedAddress,
  selectConnectedNetwork,
  selectWeb3,
} from '../../store';

export const WalletDropdown: React.FC<{
  connectWallet?: () => void;
  disconnectWallet?: () => void;
}> = ({ connectWallet, disconnectWallet }) => {
  const connectedAddress = useSelector(selectConnectedAddress);
  const connectedNetwork = useSelector(selectConnectedNetwork);
  const web3 = useSelector(selectWeb3);

  const solePermittedNetworkActive =
    skynityConfig.permittedNetworks.length == 1 &&
    isPermittedNetwork(connectedNetwork?.chainId);

  return (
    <Nav className="wallet-section">
      {connectedAddress && (
        <>
          <NavDropdown
            className={`font-small font-bold ${
              isPermittedNetwork(connectedNetwork?.chainId)
                ? 'secondary'
                : 'danger'
            } nowrap`}
            title={applyMask(connectedAddress)}
          >
            <i
              className="fa fa-power-off pointer"
              onClick={disconnectWallet}
              title="Disconnect wallet"
            ></i>
            <NavDropdown.Header>
              <Dropdown className="chain-selector">
                {solePermittedNetworkActive ? (
                  <Button>{connectedNetwork?.name}</Button>
                ) : (
                  <>
                    <Dropdown.Toggle
                      variant={
                        isPermittedNetwork(connectedNetwork?.chainId)
                          ? undefined
                          : 'danger'
                      }
                    >
                      {isPermittedNetwork(connectedNetwork?.chainId)
                        ? connectedNetwork?.name
                        : 'Wrong network'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {skynityConfig.permittedNetworks.map((network) => (
                        <Dropdown.Item
                          key={network.chainId}
                          disabled={
                            network.chainId === connectedNetwork?.chainId
                          }
                          onClick={(): void => {
                            switchToChain(web3, network.chainId);
                          }}
                        >
                          {network.addChainParams?.chainName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </>
                )}
              </Dropdown>{' '}
            </NavDropdown.Header>

            <NavDropdown.Item
              href="https://opensea.io/account/private"
              target="_blank"
              className="link-external"
            >
              My SkyLands NFTs
            </NavDropdown.Item>
          </NavDropdown>
        </>
      )}
      {!connectedAddress && (
        <button className="btn btn-secondary nowrap" onClick={connectWallet}>
          Connect wallet
        </button>
      )}
    </Nav>
  );
};
