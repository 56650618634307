import {
  GameSettings,
  Home,
  InfoBar,
  Placeholder,
  PrivateAllocation,
} from 'components';
import { RoutingPaths } from 'helpers';
import { LPPairsContracts, LPsData, TokenPrices, TokensData } from 'models';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  getBalances,
  getLPsData,
  getLPTokensPrices,
  getTokensPrices,
  selectBalances,
  selectConnectedAddress,
  selectLpPairsContracts,
  selectLPsData,
  selectStablecoinPrice,
  selectTokensData,
  selectTokensPrices,
  selectWeb3,
} from 'store';
import Web3 from 'web3';

export const Skynity: React.FC = () => {
  const dispatch = useDispatch();
  const balances = useSelector(selectBalances);
  const tokensPrices: TokenPrices = useSelector(selectTokensPrices);
  const tokensData: TokensData = useSelector(selectTokensData);
  const stableTokenPrice: string = useSelector(selectStablecoinPrice);
  const LPPairsContracts: LPPairsContracts = useSelector(
    selectLpPairsContracts
  );
  const lpsData: LPsData = useSelector(selectLPsData);
  const connectedAddress: string = useSelector(selectConnectedAddress);
  const web3: Web3 = useSelector(selectWeb3);

  useEffect(() => {
    if (
      connectedAddress &&
      tokensData &&
      LPPairsContracts &&
      stableTokenPrice
    ) {
      dispatch(
        getBalances({
          tokensData: Object.values(tokensData),
          connectedAddress,
        })
      );
      dispatch(
        getTokensPrices({
          stableTokenPrice,
          tokensData,
          stableTokenData: tokensData.USDT,
          LPPairsContracts,
        })
      );
      dispatch(
        getLPsData({
          tokensData: tokensData,
          LPPairsContracts: LPPairsContracts,
        })
      );
    }
  }, [connectedAddress, tokensData, LPPairsContracts, stableTokenPrice]);

  useEffect(() => {
    if (tokensPrices && lpsData) {
      dispatch(getLPTokensPrices({ tokensPrices, LPsData: lpsData }));
    }
  }, [tokensPrices, lpsData]);

  // const contractsAvailable =
  //   tokensData &&
  //   Object.values(tokensData).every(
  //     (tokenData: TokenData) => !!tokenData.tokenContract?.options?.address
  //   );

  return (
    <div className="app-content">
      {connectedAddress ? (
        <InfoBar
          balances={balances}
          tokensPrices={tokensPrices}
          connectedAddress={connectedAddress}
          web3={web3}
        />
      ) : (
        ''
      )}

      <Placeholder />

      <Routes>
        <Route path={RoutingPaths.Home} element={<Home />} />
        <Route path={RoutingPaths.Settings} element={<GameSettings />} />
        {/* <Route path={RoutingPaths.Staking} element={<Staking />} />
        <Route path={RoutingPaths.Vesting} element={<Vesting />} /> */}
        <Route
          path={RoutingPaths.PrivateSale}
          element={<PrivateAllocation />}
        />
        {/* <Route path={RoutingPaths.PublicSale} element={<PublicAllocation />} /> */}
        {/* <Route path={RoutingPaths.SeedSale} element={<SeedAllocation />} /> */}
        <Route path="*" element={<Navigate to={RoutingPaths.Home} replace />} />
      </Routes>
    </div>
  );
};
