import React from 'react';

export const Hero: React.FC = () => (
  // const connectedNetwork = useSelector(selectConnectedNetwork);
  // const web3 = useSelector(selectWeb3);

  // const [tokenAddress, setTokenAddress] = useState<string>();
  // const [nftAddress, setNftAddress] = useState<string>();

  // useEffect(() => {
  //   if (connectedNetwork) {
  //     setTokenAddress(
  //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //       (skynityConfig.tokens.SDT.tokenContractJSON.networks as any)[
  //         connectedNetwork.chainId
  //       ]?.address
  //     );
  //     setNftAddress(
  //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //       (skynityConfig.nftContractJSON.networks as any)[
  //         connectedNetwork.chainId
  //       ]?.address
  //     );
  //   }
  // }, [connectedNetwork]);

  <div className="hero">
    <div className="container text-center">
      <div className="hero-wrapper">
        <h1 className="hero-header mb-24">
          Welcome to <div className="font-bold">SkyNity</div>
        </h1>

        <h3 className="hero-description">Get ready to fight in the sky</h3>

        {/* TODO: Update description */}
        {/* <h3 className="hero-description-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc varius
          ut ex sit amet tempus. Integer porttitor pellentesque leo eget
          pretium. Aliquam ut dui ligula. Integer volutpat dapibus mi. Aliquam
          eu nulla ut ipsum blandit hendrerit rhoncus vitae nisl. Duis libero
          sem, congue et dapibus nec, ornare at lorem.
          </h3> */}

        <div className="hero-buttons-container">
          <a
            href="https://skynity.gitbook.io/wiki"
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary"
          >
            Game Wiki
          </a>

          {/* TODO: Update or remove */}
          {/* <Link to={RoutingPaths.PrivateSale} className="btn btn-primary">
              Private Sale
            </Link>
            <Link
              to={RoutingPaths.Staking}
              className="btn btn-primary inactive"
            >
              Stake
            </Link> */}
        </div>

        {/* {connectedNetwork && (
            <h3 className="hero-description-2 hero-tokens-info">
              <div>SkyDust SDT token contract address:</div>
              <div>
                <span className="bold">{tokenAddress}</span>
                &nbsp;&nbsp;
                <span className="nowrap">
                  <a
                    className="link link-secondary fa-regular fa-copy pointer"
                    onClick={(): void => {
                      navigator.clipboard.writeText(tokenAddress);
                    }}
                    title="Copy to clipboard"
                  ></a>
                  &nbsp;
                  <a
                    className="metamask-button link link-secondary pointer"
                    title="Add to metamask"
                    onClick={(): void => {
                      addTokenToWallet(web3, connectedNetwork.chainId);
                    }}
                  >
                    <img src={metamaskFox} className=""></img>
                  </a>
                </span>
              </div>
              <div>SkyLands NFT collection address:</div>
              <div>
                <span className="bold">{nftAddress}</span>
                &nbsp;&nbsp;
                <span className="nowrap">
                  <a
                    className="link link-secondary fa-regular fa-copy pointer"
                    onClick={(): void => {
                      navigator.clipboard.writeText(nftAddress);
                    }}
                    title="Copy to clipboard"
                  ></a>
                  &nbsp;
                  <a
                    href={
                      skynityConfig.additionalData.skyLandsNftMarketplaceLink
                    }
                    target="_blank"
                    rel="noreferrer"
                    title="Trade on NFT marketplace"
                    className="link link-secondary fa-solid fa-cart-shopping"
                  ></a>
                  &nbsp;
                  <a
                    href={skynityConfig.additionalData.skyLandsNftBridgeLink}
                    target="_blank"
                    rel="noreferrer"
                    title="Bridge between networks"
                    className="link link-secondary fa-solid fa-right-left"
                  ></a>
                </span>
                &nbsp;
              </div>
            </h3>
          )} */}
      </div>
    </div>
  </div>
);
