import { Contract } from 'web3-eth-contract';

import { ContractJSON } from './contract.model';
import { MainTokenSymbol, StableTokenSymbol } from './token.model';

export enum AllocationPoolType {
  SC = 'Stablecoin Allocation',
  // SA = 'Single Asset Staking',
}

export enum AllocationPoolSubtype {
  Private = 'private',
  Public = 'public',
  Seed = 'seed',
}

export enum AllocationPoolName {
  SCAllocation = 'USD-based Allocation',
  // SDTAllocation = 'SDT-based Allocation',
}

export enum AllocationPoolItemName {
  BUSDSkynityAllocation = 'SkyNity pre-game $SDT private sale allocation (BUSD)',
  USDTSkynityAllocation = 'SkyNity pre-game $SDT private sale allocation (USDT)',
  BUSDSkynityPublicAllocation = 'SkyNity pre-game $SDT public sale allocation (BUSD)',
  USDTSkynitySeedAllocation = 'SkyNity pre-game $SDT seed sale allocation (USDT)',
}

export interface AllocationPoolItemAllocationData {
  allocationAllowance: string;
  allocatedTokens: string;
  allocationLimit: string;
  allocationLeft: string;
  totalSupply: string;
  maxTotalSupply?: string;
  // isAllocated: boolean;
  // blockTimestamp: string;
}

export interface AllocationConfig {
  pools: AllocationPoolConfig[];
}

export interface AllocationPoolItemConfig {
  poolItemName: AllocationPoolItemName | string;
  poolItemContractJSON: ContractJSON;
  hasWhitelist: boolean;
  isActive: boolean;
}

export interface AllocationPoolItemData {
  poolItemName: AllocationPoolItemName | string;
  poolItemContract: Contract;
  addressCanAllocate?: boolean;
  isActive: boolean;
}

export interface AllocationPoolConfig {
  poolType: AllocationPoolType;
  poolName: AllocationPoolName;
  poolDescription: string;
  poolItems: AllocationPoolItemConfig[];
  poolSubtype: AllocationPoolSubtype;
  poolTokenSymbol: MainTokenSymbol | StableTokenSymbol;
}

export interface AllocationPoolData {
  poolType: AllocationPoolType;
  poolName: AllocationPoolName;
  poolDescription: string;
  poolItems: AllocationPoolItemData[];
  poolSubtype: AllocationPoolSubtype;
  poolTokenSymbol: MainTokenSymbol | StableTokenSymbol;
}
