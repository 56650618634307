import { AllocationPoolSubtype } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBalances, selectConnectedAddress } from 'store';
import { selectStablecoinPrice } from 'store/selectors/stablecoin.selectors';

import AllocationPools from './AllocationPools';

export const PrivateAllocation: React.FC = () => {
  const connectedAddress = useSelector(selectConnectedAddress);
  const balances = useSelector(selectBalances);
  const stableTokenPrice = useSelector(selectStablecoinPrice);

  return (
    <>
      {connectedAddress ? (
        <section className="staking allocation container">
          <div className="container-inner">
            <div className="section-container">
              <div className="section-header">
                <h3 className="section-header-title">
                  Private pre-game $SDT access
                </h3>
                <div className="section-header-description">
                  If you are enrolled for early pre-game private token access
                  (Apply on{' '}
                  <a
                    href="https://discord.com/invite/skynity"
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    discord
                  </a>
                  ), you will see options below to buy $SDT token at a special
                  price.
                  {balances && stableTokenPrice ? (
                    ''
                  ) : (
                    <div className="mt-16">
                      Connect your wallet to see allocation pools
                    </div>
                  )}
                </div>
              </div>
            </div>

            {balances && stableTokenPrice ? (
              <div className="section-container">
                <AllocationPools poolsSubtype={AllocationPoolSubtype.Private} />
              </div>
            ) : (
              ''
            )}
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default PrivateAllocation;
