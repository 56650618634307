import { DataItem } from 'components';
import { getTotalAcquiredSDT, isProduction } from 'helpers';
import { MainTokenSymbol, TokenBalances, TokenPrices } from 'models';
import React, { useEffect, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import Web3 from 'web3';

import { TruncatedNumber } from '../TruncatedNumber';

interface InfoBarProps {
  balances: TokenBalances;
  tokensPrices: TokenPrices;
  connectedAddress: string;
  web3: Web3;
}

export const InfoBar: React.FC<InfoBarProps> = ({
  balances,
  tokensPrices,
  connectedAddress,
  web3,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [preboughtTokens, setPreboughtTokens] = useState(null);

  useEffect(() => {
    if (connectedAddress) {
      getPreboughtTokens();
    }
  }, [connectedAddress]);

  const getPreboughtTokens = async (): Promise<void> => {
    try {
      const tokens = await getTotalAcquiredSDT(connectedAddress);

      setPreboughtTokens(tokens);
    } catch {
      setPreboughtTokens({ total: 'Error' });
    }
  };

  return (
    <section className="info-bar">
      <div className="container-inner">
        <div className="info-bar-container container">
          <DataItem label="SDT in wallet" iconClasses="fas fa-wallet">
            {balances ? (
              <>
                <TruncatedNumber value={balances.SDT} /> {MainTokenSymbol.SDT}
              </>
            ) : (
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            )}
          </DataItem>

          {/* TODO: disabled temporarily */}
          {/* <DataItem label="SDT Price" iconClasses="fas fa-dollar-sign">
            {tokensPrices ? (
              <a
                href={skynityConfig.additionalData.tokenChartLink}
                className="link pointer secondary font-bold"
              >
                {parseCurrency(
                  tokensPrices.SDT,
                  Number.parseFloat(tokensPrices.SDT) < 10 ? 4 : undefined
                )}
              </a>
            ) : (
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            )}
          </DataItem> */}

          {/* display only of non-zero in production*/}
          {!isProduction() || +preboughtTokens?.total ? (
            <DataItem
              label="Your pregame tokens allocation"
              iconClasses="fas fa-lock"
              tooltipHint={
                preboughtTokens && preboughtTokens?.perPool?.length > 0 ? (
                  <Table size="sm" className="table-striped m-0">
                    <thead>
                      <tr className="nowrap">
                        <th className="text-start">Presale</th>
                        <th className="text-end">Allocated SDT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {preboughtTokens.perPool.map((pool: any) => (
                        <tr key={pool.name}>
                          <td className="text-start align-middle nowrap">
                            {pool.name}
                          </td>
                          <td
                            className="text-end align-middle"
                            style={{ overflowWrap: 'anywhere' }}
                          >
                            {pool.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : undefined
              }
              tooltipPlacement="bottom"
            >
              {preboughtTokens ? (
                <>
                  <TruncatedNumber value={preboughtTokens.total} />{' '}
                  {MainTokenSymbol.SDT}
                </>
              ) : (
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                />
              )}
            </DataItem>
          ) : null}
        </div>
      </div>
    </section>
  );
};
