import skynityLogo from 'assets/svg/skynity-logo.svg';
import { RoutingPaths, skynityConfig } from 'helpers';
import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { WalletDropdown } from './WalletDropdown';

export const Header: React.FC<{
  connectWallet?: () => void;
  disconnectWallet?: () => void;
}> = ({ connectWallet, disconnectWallet }) => (
  <header className="header">
    <Navbar collapseOnSelect expand="md" variant="dark">
      <div className="container-fluid">
        <Navbar.Brand as={Link} to={RoutingPaths.Home}>
          <img src={skynityLogo} alt="Skynity logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-center flex-grow-1">
            <Nav.Link as={Link} to={RoutingPaths.Home}>
              Home
            </Nav.Link>
            <NavDropdown title="SDT Token" id="basic-nav-dropdown">
              {/* <NavDropdown.Item
                  href={skynityConfig.additionalData.tokenLink}
                  className="link secondary font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy
                </NavDropdown.Item> */}
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item
                className="link secondary"
                as={Link}
                to={RoutingPaths.Staking}
                disabled
              >
                Staking
              </NavDropdown.Item>
              <NavDropdown.Item
                className="link secondary"
                as={Link}
                to={RoutingPaths.Vesting}
                disabled
              >
                Vesting
              </NavDropdown.Item>
              {/* <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown title="SkyLands" id="basic-nav-dropdown">
              <NavDropdown.Item
                href={skynityConfig.additionalData.skyLandsNftMarketplaceLink}
                target="_blank"
                className="link-external"
              >
                Marketplace
              </NavDropdown.Item>
              <NavDropdown.Item
                href={skynityConfig.additionalData.skyLandsNftBridgeLink}
                target="_blank"
                className="link-external"
              >
                Bridge
              </NavDropdown.Item>
            </NavDropdown>

            {/* TODO: enable when ready*/}
            <NavDropdown title="Pregame Sale" id="basic-nav-dropdown">
              <NavDropdown.Item
                className="link secondary font-bold"
                as={Link}
                to={RoutingPaths.PrivateSale}
                // disabled={true}
              >
                Private
              </NavDropdown.Item>
              {/* <NavDropdown.Item
                className="link secondary font-bold"
                as={Link}
                to={RoutingPaths.PublicSale}
              >
                Public
              </NavDropdown.Item> */}
            </NavDropdown>

            <Nav.Link as={Link} to={RoutingPaths.Settings}>
              Game panel
            </Nav.Link>
          </Nav>
          <WalletDropdown
            connectWallet={connectWallet}
            disconnectWallet={disconnectWallet}
          />
        </Navbar.Collapse>
      </div>
    </Navbar>
  </header>
);
